/*-----------------------------------------*/
/*  MIXIN: PlaceHolder
/*-----------------------------------------*/
/*-----------------------------------------*/
/*  ClearFix
/*-----------------------------------------*/
.clearfix:after, #box-header:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix, #box-header {
  display: inline-block;
}

html[xmlns] .clearfix, html[xmlns] #box-header {
  display: block;
}

* html .clearfix, * html #box-header {
  height: 1%;
}

/*-----------------------------------------*/
/*  Vertical Center with Bootstrap
/*-----------------------------------------*/
.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

/*-----------------------------------------*/
/*  Table with Div
/*-----------------------------------------*/
.div-table {
  display: table;
  width: auto;
  background-color: #eee;
  border: 1px solid  #666666;
  border-spacing: 5px;
  /*cellspacing:poor IE support for  this*/
}

.div-table-row {
  display: table-row;
  width: auto;
  clear: both;
}

.div-table-col {
  float: left;
  /*fix for  buggy browsers*/
  display: table-column;
  width: 200px;
  background-color: #ccc;
}

/*-----------------------------------------*/
/*  Not-Padding for Bootstrap
/*-----------------------------------------*/
.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.row-no-padding [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/*-----------------------------------------*/
/*  Base
/*-----------------------------------------*/
.form-base input, .form-base textarea, .form-base select {
  width: 280px;
  background-color: #F8F8F8;
  box-shadow: #EDEDED 0px 2px 2px inset;
}

.form-base select {
  width: 294px;
  height: 30px;
}

.form-base #inputCidade {
  width: 212px;
}

.form-base #inputEstado {
  width: 50px;
}

.form-base .control-group {
  margin-bottom: 13px;
}

.form-base .control-group {
  font-weight: bold;
}

.form-base .control-group .control-div {
  float: left;
  width: 160px;
  text-align: right;
}

.side-base {
  width: 235px;
  float: left;
  padding-right: 30px;
}

.side-base hr {
  margin: 0px;
  padding: 0px;
  border: none;
  border-top: 1px dashed #ccc;
}

.side-base .space {
  margin: 0px;
  padding: 0px;
  border: none;
  height: 20px;
}

.body-base {
  padding-left: 30px;
  float: left;
  width: 590px;
  min-height: 400px;
  border-left: 1px dashed #CCC;
}

.body-base .title {
  padding-left: 180px;
}

.body-base .title p {
  line-height: 1.3em;
}

.form-status-base {
  display: none;
  float: left;
  padding: 5px 0px 4px 10px;
  background-color: #f9f7d7;
  width: 207px;
  margin-left: 10px;
  border-radius: 6px;
  border: 1px solid #f0ea7e;
}

.form-status-base img {
  padding: 0px 10px 0px 0px;
}

/*-----------------------------------------*/
/*  HTML & BODY
/*-----------------------------------------*/
body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0px;
  padding: 0px;
  color: #555555;
  min-width: 320px;
  line-height: 1.3em;
  background-color: #000;
}

/*-----------------------------------------*/
/*  DEFAULT
/*-----------------------------------------*/
img.responsive {
  width: 100%;
}

div {
  display: block;
  position: relative;
  margin: 0px;
  padding: 0px;
}

p {
  margin: 0px;
  padding: 0px 0px 13px 0px;
  font-size: 14px;
  font-weight: normal;
}

p.pre-line {
  white-space: pre-line;
}

ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

ul li {
  font-size: 13px;
  margin-bottom: 10px;
}

ul.normal {
  list-style: disc;
  list-style-position: inside;
}

h1, h2, h3, h4, h5, h4, h5, h5 {
  color: black;
}

h1 {
  font-size: 26px;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
}

h2 {
  font-size: 23px;
  font-weight: bold;
  margin: 0px;
  padding: 0px 0px 10px 0px;
  line-height: 1em;
}

h3, h4, h5 {
  font-weight: normal;
  margin: 0px;
  padding: 12px 0px 5px 0px;
  line-height: 1em;
}

h4, h5 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

a {
  color: black;
}

a:hover {
  color: black;
}

a.blue {
  text-decoration: underline;
  color: #554dfd;
}

/*-----------------------------------------*/
/*  HEADER
/*-----------------------------------------*/
#section-header {
  position: relative;
  z-index: 999;
  background-color: #000000;
  background-image: url("../images/site/header-background.jpg");
  background-position: bottom;
  background-repeat: repeat-x;
}

#section-header #logo {
  margin: 24px 0px 24px 0px;
  padding-left: 0px;
}

#section-header #section-header-bar {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: white;
  color: #333;
  padding: 8px 10px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: #ccc 0px 1px 1px 0px;
}

#section-header #section-header-bar a {
  display: inline-block;
  font-size: 11px;
  border-right: 1px solid #ccc;
  margin: 0px 6px 0px 0px;
  padding: 0px 6px 0px 0px;
}

#section-header #section-header-bar a:last-child {
  margin-right: 0px;
  border-right: none;
}

@media (max-width: 668px) {
  #section-header #logo {
    margin-top: 64px;
  }
  #section-header #sectionBar {
    text-align: center;
    left: 0px !important;
  }
}

/*-----------------------------------------*/
/*  MAIN
/*-----------------------------------------*/
#section-main {
  z-index: 4;
  background-color: #808080;
  background-image: -owg-linear-gradient(#333333, #CACACA);
  background-image: linear-gradient(#333333, #CACACA);
}

#section-main #section-main-content {
  padding: 6px;
  background-color: #fff;
}

/*-----------------------------------------*/
/*  FOOTER
/*-----------------------------------------*/
#section-footer {
  background-color: #000000;
  background-image: url("../images/site/footer-background.jpg");
  background-repeat: repeat-x;
}

#section-footer .container {
  padding: 0px;
}

#section-footer #section-footer-content {
  background: #fff;
  height: 64px;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 12px 6px 0px 6px;
  text-transform: uppercase;
}

#section-footer #section-footer-content #milho {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 0px;
}

#section-footer #section-footer-content #bar {
  position: relative;
  font-size: 10px;
  color: #eaeaea;
  width: 100%;
  height: 72px;
  background-image: url("../images/site/footer-bar.png");
  background-size: 100% 72px;
  background-repeat: no-repeat;
  padding: 45px 15px 0px 15px;
}

#section-footer #section-footer-content #bar a {
  color: #eaeaea;
}

/*-----------------------------------------*/
/* Extra small screen / phone
/*-----------------------------------------*/
@media (max-width: 668px) {
  #section-footer #bar .pull-left {
    margin-top: 50px;
  }
  #section-footer #bar .pull-left span {
    display: block;
    clear: both;
  }
  #section-footer #bar .pull-right {
    margin-top: 50px;
  }
}

/*-----------------------------------------*/
/*  FORM
/*-----------------------------------------*/
form {
  font-size: 13px;
}

form .status {
  display: none;
  margin-left: 5px;
  padding: 8px 9px 8px;
  font-size: 11px;
  border-radius: 4px;
}

form .status.done {
  background-color: #1DB14C;
  color: #fff;
}

form .status.wait {
  padding-right: 15px;
  background-color: #f0f0f0;
}

form .status.wait img {
  margin-right: 5px;
  height: 16px;
}

form .status.fail {
  background-color: #FFCAC7;
}

form .status.invalidate {
  background-color: #FFCAC7;
}

form span.error {
  font-size: 11px;
  color: red;
}

/*-----------------------------------------*/
/*  TITLES
/*-----------------------------------------*/
.title-a {
  font-size: 17px;
  color: #333;
  border-bottom: 1px dotted #333;
}

.title-a .title-a-label {
  display: inline-block;
  padding-bottom: 6px;
  padding-right: 6px;
}

.title-a .title-a-arrow {
  display: inline-block;
  background-image: url("../images/site/title-arrow.png");
  background-repeat: no-repeat;
  min-width: 12px;
  min-height: 8px;
}

/*-----------------------------------------*/
/*  UTILS
/*-----------------------------------------*/
.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.text-center {
  text-align: center;
}

/*-----------------------------------------*/
/*  IMAGES
/*-----------------------------------------*/
img.shadow {
  border: 6px solid #FFF;
  box-shadow: #d0d0d0 1px 1px 10px;
  margin: 10px 10px 0px 10px;
}

img.shadow-left {
  border: 6px solid #FFF;
  box-shadow: #d0d0d0 1px 1px 10px;
  margin: 10px 10px 10px 0px;
}

img.shadow-center {
  border: 6px solid #FFF;
  box-shadow: #d0d0d0 1px 1px 10px;
  display: block;
  margin: 10px auto 20px auto;
}

img.center {
  display: block;
  margin: 10px auto 20px auto;
}

/*-----------------------------------------*/
/*  UL - Style One
/*-----------------------------------------*/
ul.style-one li {
  border-bottom: 1px dotted #D9D9D9;
  margin: 0px;
  padding: 0px;
}

ul.style-one li a {
  background-image: url("../images/site/ul-arrow.png");
  background-position: 5px 11px;
  background-repeat: no-repeat;
  padding: 8px 0px 8px 20px;
  display: block;
  transition: all 300ms ease;
}

ul.style-one li a:hover {
  background-position: 7px 11px;
  padding-left: 22px;
  background-color: #f4f4f4;
}

ul.style-one li:last-child {
  margin-bottom: 0px;
  border-bottom: none;
}

/*-----------------------------------------*/
/*  ALERT
/*-----------------------------------------*/
#site-message {
  border-radius: 0px;
  margin: 0px;
}

.alert li {
  margin-left: 10px;
}

/*-----------------------------------------*/
/*  BUTTON / FILE
/*-----------------------------------------*/
form .btn-file {
  position: relative;
  overflow: hidden;
}

form .btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

/*-----------------------------------------*/
/*  MENU PRINCIPAL
/*-----------------------------------------*/
#section-menu .container {
  padding-left: 0px !important;
}

#section-menu .tabs {
  list-style: none;
  margin: 0px;
  font-size: 12px;
}

/*-----------------------------------------*/
/*  MENU LI
/*-----------------------------------------*/
#section-menu li {
  position: relative;
  margin: 0px 0px 0px 0px;
  float: left;
}

/*-----------------------------------------*/
/*  MENU LI A
/*-----------------------------------------*/
#section-menu li a {
  display: block;
  text-decoration: none;
  color: #242424;
  font-size: 15px;
  background: #F8AF00;
  border-bottom: 5px solid #E47723;
  /* Only round the top corners */
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  box-sizing: content-box;
  transition-property: background-color;
  transition-duration: 0.5s;
  margin-right: 1px;
  margin-top: 4px;
  padding: 10px 12px 8px 12px;
}

#section-menu li.active a {
  color: black;
  z-index: 777;
  /* Colors when tab is active */
  padding-top: 12px;
  border-bottom: 5px solid white;
  margin-bottom: 0px;
  background: white;
  /*= Cor selecionado =*/
  color: black;
  margin-top: 0px;
  padding-bottom: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

#section-menu li.active a:hover {
  background: white;
}

#section-menu li a:hover {
  background: #66BB1B;
  margin-top: 0px;
}

#section-menu li:first-child a {
  border-top-left-radius: 4px;
}

#section-menu li:last-child a {
  border-top-right-radius: 4px;
}

/*-----------------------------------------*/
/*  Medium screen / desktop
/*-----------------------------------------*/
@media (max-width: 992px) {
  #section-header #section-menu li a {
    padding-left: 9px;
    padding-right: 9px;
  }
}

/*-----------------------------------------*/
/* Small screen / tablet
/*-----------------------------------------*/
@media (max-width: 768px) {
  #section-menu li a {
    padding-left: 7px;
    padding-right: 7px;
  }
}

/*-----------------------------------------*/
/* Extra small screen / phone
/*-----------------------------------------*/
@media (max-width: 668px) {
  #section-menu .container {
    padding-left: 16px !important;
  }
  #section-menu li {
    width: 32%;
    margin-bottom: 3px;
  }
  #section-menu li a, #section-menu li a:hover {
    margin-right: 1px;
    margin-top: 4px;
    padding: 10px 12px 8px 12px;
  }
  #section-menu li.active a {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom: 5px solid #e5e5e5;
  }
}

.block {
  position: relative;
  font-size: 12px;
}

.block .block-title {
  z-index: 555;
  position: absolute;
  top: -16px;
  left: 0px;
  right: 0px;
  background-image: url("../images/site/block-title-arrow.png");
  background-position: center bottom;
  background-repeat: no-repeat;
}

.block .block-title .block-title-content {
  font-size: 13px;
  text-align: center;
  width: 100px;
  margin: 0px auto;
  display: block;
  background-image: url("../images/site/block-title-background.jpg");
  background-position: top left;
  background-repeat: repeat-x;
  background-color: #E37422;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  padding: 3px 14px;
  margin-bottom: 7px;
}

.block .block-body {
  z-index: 554;
  margin-top: 30px;
  background-image: url("../images/site/block-background.jpg");
  background-position: top left;
  background-repeat: repeat-x;
  background-color: #EFEFEF;
  border: 1px solid #F2F2F2;
  border-radius: 4px;
  padding: 23px 15px 15px 15px;
}

.block .block-body .map {
  position: absolute;
  background: #CCCCCC;
  padding: 4px;
}

/*-----------------------------------------*/
/*  SUBPAGE / MAIN (images top)
/*-----------------------------------------*/
#section-main-header {
  background-image: url("../images/site/headers/background-bottom.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% 29px;
  padding-bottom: 30px;
}

#section-main-header .image {
  background-color: #f4f4f4;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 136px;
}

#section-main-header .image.abimilho {
  background-image: url("../images/site/headers/abimilho.jpg");
}

#section-main-header .image.associados {
  background-image: url("../images/site/headers/associados.jpg");
}

#section-main-header .image.milho {
  background-image: url("../images/site/headers/milho.jpg");
}

#section-main-header .image.estatisticas {
  background-image: url("../images/site/headers/estatisticas.jpg");
}

#section-main-header .image.receitas {
  background-image: url("../images/site/headers/receitas.jpg");
}

#section-main-header .image.noticias {
  background-image: url("../images/site/headers/noticias.jpg");
}

#section-main-header .image.clipping {
  background-image: url("../images/site/headers/clipping.jpg");
}

#section-main-header .image.downloads {
  background-image: url("../images/site/headers/downloads.jpg");
}

#section-main-header .image.associese {
  background-image: url("../images/site/headers/associese.jpg");
}

#section-main-header .image.curriculos {
  background-image: url("../images/site/headers/curriculos.jpg");
}

#section-main-header .image.contato {
  background-image: url("../images/site/headers/contato.jpg");
}

/*-----------------------------------------*/
/*  SUBPAGE / BOX
/*-----------------------------------------*/
/*-----------------------------------------*/
/*  SUBPAGE / BOX HEADER
/*-----------------------------------------*/
#box-header {
  position: relative;
  display: block;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #F6F6F6;
  min-height: 40px;
  border: 1px solid #CFCFCF;
}

#box-header #box-header-title {
  padding: 11px 0px 0px 10px;
}

#box-header #box-header-title.back {
  padding: 0px;
}

#box-header #box-header-title.back a.btn {
  margin: 7px 0px 0px 7px;
  padding: 4px 7px 4px 6px;
  background-color: #fff;
  color: #333;
  font-size: 11px;
  border: 1px solid #d7d7d7;
}

#box-header #box-header-title.back a.btn span {
  margin-right: 4px;
}

#box-header #box-header-search {
  position: absolute;
  top: 6px;
  right: 6px;
  text-align: right;
}

#box-header #box-header-search input {
  font-size: 12px;
  padding: 0px 6px;
  height: 28px;
  width: 180px;
}

#box-header ul#box-header-menu {
  width: 100%;
  padding: 0;
  margin: 0px 1px 0px 1px;
  color: #fff;
  font-family: Verdana, Geneva, sans-serif;
  font-size: 12px;
  white-space: nowrap;
  list-style-type: none;
}

#box-header ul#box-header-menu li {
  float: left;
  height: 40px;
  margin: 0px;
}

#box-header ul#box-header-menu li a {
  display: block;
  margin: 1px 0px 0px 0px;
  padding: 11px 14px 10px 14px;
  color: #000;
  text-decoration: none;
  border-right: 1px solid #CDCDCD;
}

#box-header ul#box-header-menu li a:hover {
  background-color: #FFF;
  color: #000;
}

#box-header ul#box-header-menu li.active a {
  background-image: url("../images/site/box-menu-active.gif");
  background-repeat: repeat-x;
  background-position: center;
  font-weight: bold;
}

#box-header ul#box-header-menu li:first-child a {
  border-top-left-radius: 6px;
}

@media (max-width: 481px) {
  #box-header #box-header-menu li {
    float: inherit !important;
  }
  #box-header #box-header-menu li:first-child a {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}

/*-----------------------------------------*/
/*  SUBPAGE / BOX MAIN
/*-----------------------------------------*/
#box-main {
  position: relative;
  margin-top: 0px;
  background-image: url("../images/site/box-left-2.jpg"), url("../images/site/box-right-2.jpg"), url("../images/site/box-main-2.jpg");
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-position: left top, right top, left top;
  min-height: 280px;
  padding: 0px 1px 0px 0px;
}

#box-main #box-main-image {
  margin-top: 0px;
  padding: 1px 1px 0px 2px;
  width: 100%;
  overflow: hidden;
}

#box-main #box-main-image img {
  width: 100%;
}

#box-main #box-main-body {
  padding: 28px 18px 10px 18px;
}

#box-main #box-main-body h2 {
  margin-top: 20px;
}

#box-main #box-main-body h2:first-child {
  margin-top: 0px;
}

/*-----------------------------------------*/
/*  SUBPAGE / ASSOCIADOS
/*-----------------------------------------*/
body.site.associados .item {
  margin-bottom: 16px;
}

body.site.associados .item .item-content {
  position: relative;
  border: 1px solid #CCC;
  box-sizing: border-box;
}

body.site.associados .item .item-content:hover {
  box-shadow: #dadada 0px 0px 12px 0px;
}

body.site.associados .item .item-content:hover .icon_link_associado {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

body.site.associados .item .image {
  margin-top: 0px;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

body.site.associados .item .image img {
  max-width: 245px;
  width: 100%;
}

body.site.associados .item .footer {
  position: relative;
  bottom: 0px;
  width: 100%;
  background-color: #F4F4F4;
  border-top: 1px solid #CCC;
  padding: 6px 0px 0px 7px;
}

body.site.associados .item .footer .title {
  font-size: 12px;
  color: #494949;
  margin: 0px;
  padding: 0px;
  height: 24px;
}

body.site.associados .item .footer .icon_link_associado {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  transition-property: opacity;
  transition-duration: 400ms;
  width: 22px;
  height: 22px;
  background: url(../images/site/associados/link.png);
  background-repeat: no-repeat;
  position: absolute;
  right: 5px;
  top: 3px;
}

/*-----------------------------------------*/
/*  SUBPAGE / ASSOCIADOS
/*-----------------------------------------*/
body.site.downloads #boxMain-body {
  padding: 40px 0px 0px 40px !important;
}

body.site.downloads .item {
  background-image: url(../images/site/downloads/item-background.jpg);
  background-position: top-left;
  background-repeat: no-repeat;
  float: left;
  width: 315px;
  height: 115px;
  position: relative;
  padding-top: 6px;
  font-size: 12px;
}

body.site.downloads .item .titulo {
  width: 315px;
  text-align: center;
  float: left;
  color: #FFF;
  font-size: 14px;
}

body.site.downloads .item .descricao {
  position: absolute;
  top: 40px;
  left: 80px;
  padding-right: 10px;
}

body.site.downloads .item .down {
  background-image: url(../images/site/ul-arrow.png);
  background-position: 0px 3px;
  background-repeat: no-repeat;
  position: absolute;
  top: 78px;
  left: 80px;
  margin: 10px 0px 0px 0px;
  padding: 0px 0px 6px 12px;
}

body.site.downloads .item .down a {
  text-decoration: underline;
}

body.site.downloads .item .image {
  position: absolute;
  top: 40px;
  left: 10px;
}

.linha {
  width: 794px;
  height: 2px;
  background-color: #CCC;
}

.tabela {
  background-color: #ecf0f2;
  font-size: 11px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 1px;
  border: thin dotted #CCC;
  margin-top: 30px;
}

.tabela caption {
  background: #fff;
  text-align: center;
  vertical-align: bottom;
  padding: 10px 0px 10px 0px;
  font-weight: bold;
  font-size: 13px;
  color: #333;
  border: 0px solid #FFF;
  border-bottom: 2px solid #FFF;
}

.tabela tr {
  text-align: center;
  height: 24px;
  padding-top: 5px;
}

.titulo {
  background-color: #FFF;
  text-align: center;
  font-weight: bold;
  font-size: 10px;
  color: #333;
  height: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
  width: 790px;
  line-height: 1.5em;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tabelaTituloColuna {
  background-color: #C6D9F1;
  text-align: center;
  height: 24px;
  padding-top: 5px;
}

.tabelaTituloColuna.claro {
  background-color: #E0E6EE;
  text-align: center;
  height: 24px;
  padding-top: 5px;
}

.tabelaTituloColuna.escuro {
  background-color: #8EA2C3;
  height: 24px;
  padding-top: 5px;
  color: #FFF;
}

.tabelaTituloColuna.negrito {
  height: 24px;
  padding-top: 5px;
  color: #3F5795;
  font-weight: bold;
}

.tabelaTituloColuna.branco {
  background-color: #FFF;
  height: 28px;
  padding-top: 5px;
  color: 3F5795;
}

.tabela td {
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-style: solid;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-top-color: #FFF;
  border-right-color: #FFF;
  border-bottom-color: #FFF;
  border-left-color: #FFF;
  padding-left: 5px;
}

.tabelaTituloDestaqueTop {
  background-color: #b8cbe3;
  text-align: center;
  height: 22px;
  padding-top: 5px;
  vertical-align: middle;
  font-weight: bold;
}

.tabelaTituloDestaque {
  background-color: #C6D9F1;
  text-align: center;
  height: 22px;
  padding-top: 5px;
  vertical-align: middle;
}

.tabelaTituloDestaque2 td {
  background-color: #bed2ea;
  text-align: center;
  height: 20px;
  padding-top: 5px;
  vertical-align: middle;
  border-top: thin solid #999;
  border-bottom: thin solid #999;
}

.tabelaInfo {
  font-family: Cambria, serif;
  font-size: 11px;
  font-style: italic;
  font-weight: normal;
  text-transform: normal;
  letter-spacing: normal;
  line-height: 1.5em;
  padding-top: 10px;
  color: #666;
}

.tabelaInfo .t_titulo {
  font-weight: bold;
  padding-left: 10px;
  padding-right: 2px;
}

.tabelaInfo2 {
  font-family: Cambria, serif;
  font-size: 11px;
  font-style: italic;
  font-weight: normal;
  text-transform: normal;
  letter-spacing: normal;
  line-height: 1.5em;
  padding-top: 10px;
  color: #666;
  height: 90px;
  border-bottom: 1px solid #ccc;
}

.tabelaInfo2 .t_image {
  margin-right: 10px;
}

.tabelaInfo2 .t_image a {
  display: inline-block;
  border: 1px solid #ccc;
  padding: 6px;
}

.tabelaInfo2 .t_titulo a {
  color: #666;
}

.tabelaInfo2 .t_image img {
  height: 45px;
}

.tabelaInfo2 .t_titulo {
  margin-right: 20px;
}

.tabela.table-theme-a td {
  padding-right: 4px;
  text-align: right;
}

.tabela.table-theme-a td:first-child {
  text-align: center;
}

.tabela.table-theme-a .tabelaTituloColuna td {
  text-align: center;
}

.tabela.table-theme-a .tabelaTituloColuna.claro td {
  background-color: #dadfea;
}

.tabela.table-theme-a .tabelaTituloDestaque td {
  text-align: center;
}

.tabela.table-theme-b td {
  padding-right: 4px;
  text-align: center;
}

.tabela.table-theme-b td:first-child {
  text-align: center;
}

.tabela.table-theme-b .tabelaTituloColuna td {
  text-align: center;
}

.tabela.table-theme-b .tabelaTituloColuna.claro td {
  background-color: #dadfea;
}

.tabela.table-theme-b .tabelaTituloDestaque td {
  text-align: center;
}

tr.first-text-left td:first-child {
  text-align: left !important;
}

tr.first-text-center td:first-child {
  text-align: center !important;
}

tr.text-center td {
  text-align: center !important;
}

tr.text-left td {
  text-align: left !important;
}

tr.text-right td {
  text-align: right !important;
  padding-right: 4px;
}

tr td.text-center {
  text-align: center !important;
}

tr td.text-left {
  text-align: left !important;
}

tr td.text-right {
  text-align: right !important;
  padding-right: 4px;
}

/*-----------------------------------------*/
/*  OWL-CAROUSEL / CONTROLS
/*-----------------------------------------*/
.owl-carousel .owl-controls {
  display: block;
  position: absolute;
  top: 50%;
  width: 100%;
}

.owl-carousel .owl-controls .owl-nav {
  z-index: 300;
  text-align: center;
  font-size: 11px;
}

.owl-carousel .owl-controls .owl-nav div.arrow {
  font-family: Arial, sans-serif;
  width: 44px;
  height: 44px;
  font-size: 42px;
  border: 3px solid white;
  border-radius: 23px;
  line-height: 30px;
  text-align: center;
  background-color: #222;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  opacity: 0.6;
}

.owl-carousel .owl-controls .owl-nav div.arrow:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
  opacity: 0.9;
}

.owl-carousel .owl-controls .owl-nav .owl-next div.arrow {
  padding-left: 3px;
}

.owl-carousel .owl-controls .owl-nav .owl-prev {
  cursor: pointer;
  display: block;
  position: absolute;
  left: 14px;
  color: white;
}

.owl-carousel .owl-controls .owl-nav .owl-next {
  z-index: 301;
  cursor: pointer;
  display: block;
  position: absolute;
  right: 14px;
  color: white;
}

.owl-carousel .owl-controls .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  margin-top: 6px;
}

.owl-carousel .owl-controls .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.owl-carousel .owl-controls .owl-dots .owl-dot span {
  width: 13px;
  height: 13px;
  margin: 5px 7px;
  background: #666666;
  display: block;
  -webkit-backface-visibility: visible;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  opacity: 0.6;
  border-radius: 36px;
  transition-property: opacity;
  transition-duration: 400ms;
  transition-timing-function: ease;
}

.owl-carousel .owl-controls .owl-dots .owl-dot.active span, .owl-carousel .owl-controls .owl-dots .owl-dot:hover span {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  background: #F8AF00;
}

/*-----------------------------------------*/
/*  SECTION-SLIDER
/*-----------------------------------------*/
body #section-carousel .owlcarousel .item {
  display: none;
}

body #section-carousel .owlcarousel .item:first-child {
  display: block;
}

body #section-carousel #owlbar {
  background: #A1A1A1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  height: 78px;
  border-top: 1px solid #333;
  background-image: url("../images/site/home/bar-background.jpg");
  background-position: top;
  background-repeat: repeat-x;
  padding: 10px 0px;
}

body #section-carousel #owlbar #owlbar-control {
  float: left;
  margin: 18px 0px 0px 10px;
  font-size: 18px;
}

body #section-carousel #owlbar #owlbar-control div:last-child {
  margin-right: 0px;
}

body #section-carousel #owlbar #owlbar-control #btn-prev, body #section-carousel #owlbar #owlbar-control #btn-next {
  cursor: pointer;
  padding: 0px 10px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  opacity: 0.5;
}

body #section-carousel #owlbar #owlbar-control #btn-prev:hover, body #section-carousel #owlbar #owlbar-control #btn-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

body #section-carousel #owlbar #owlbar-control #btn-prev:active {
  padding: 0px 11px 0px 9px;
}

body #section-carousel #owlbar #owlbar-control #btn-next:active {
  padding: 0px 9px 0px 11px;
}

body #section-carousel #owlbar #owlbar-control #owlbar-control-current {
  padding: 1px 5px 0px 5px;
  color: #6b6b6b;
  font-size: 14px;
}

body #section-carousel #owlbar #owlbar-control #owlbar-control-current span {
  color: #333;
  font-size: 16px;
}

body #section-carousel #owlbar .owl-dot {
  cursor: pointer;
}

body #section-carousel #owlbar #owlbar-line {
  float: left;
  margin: 0px 20px 0px 10px;
  height: 100%;
  width: 2px;
  background-image: url("../images/site/home/bar-line.png");
  background-position: top;
  background-repeat: repeat-y;
}

body #section-carousel #owlbar #owlbar-labels {
  position: absolute;
  left: 140px;
  right: 140px;
  padding: 10px 0px 0px 0px;
}

body #section-carousel #owlbar .owlbar-label {
  position: absolute;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  transition-property: opacity;
  transition-duration: 400ms;
  transition-timing-function: ease;
}

body #section-carousel #owlbar .owlbar-label.active {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

body #section-carousel #owlbar .owlbar-label .owlbar-label-title {
  font-size: 16px;
}

body #section-carousel #owlbar .owlbar-label .owlbar-label-subtitle {
  font-size: 11px;
}

@media (max-width: 668px) {
  body #section-carousel #owlbar {
    height: 48px;
  }
  body #section-carousel #owlbar #owlbar-control {
    float: left;
    margin: 4px 0px 0px 10px;
  }
  body #section-carousel #owlbar #owlbar-dots {
    margin-top: 6px;
  }
  body #section-carousel #owlbar #owlbar-labels {
    display: none;
  }
}

body #owlbar-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  margin-top: 20px;
  margin-right: 20px;
}

body #owlbar-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

body #owlbar-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 0px 5px 0px 0px;
  background: #666666;
  display: block;
  -webkit-backface-visibility: visible;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  opacity: 0.6;
  border-radius: 36px;
  transition-property: opacity;
  transition-duration: 400ms;
  transition-timing-function: ease;
}

body #owlbar-dots .owl-dot.active span, body #owlbar-dots .owl-dot:hover span {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  background: #F8AF00;
}

/*-----------------------------------------*/
/*  SECTION-NEWS
/*-----------------------------------------*/
body.site.home #section-news {
  padding: 30px 20px 0px 20px;
}

body.site.home #section-news #col-news-abimilho {
  border-right: 1px dotted #ccc;
}

body.site.home #section-news .news {
  margin: 12px 0px 12px 0px;
  border-bottom: 1px dotted #ccc;
  position: relative;
  min-height: 84px;
}

body.site.home #section-news .news .news-image {
  width: 110px;
}

body.site.home #section-news .news .news-title {
  margin-left: 110px;
  padding: 0px 0px 0px 12px;
}

body.site.home #section-news .news .news-title .news-title-label {
  font-weight: bold;
}

body.site.home #section-news .news .news-title .news-title-excerpt {
  color: #333333;
  font-size: 13px;
}

body.site.home #section-news .news:last-child {
  margin-bottom: 0px;
  border-bottom: none;
}

body.site.home #section-news #clipping-col-1 {
  margin: 12px 0px 12px 0px;
}

body.site.home #section-news .clipping-item {
  font-weight: normal;
  font-size: 13px;
  border-bottom: 1px dotted #ccc;
  margin: 8px 0px;
  padding-bottom: 8px;
}

body.site.home #section-news .clipping-item:first-child {
  margin-top: 0px;
}

body.site.home #section-news .clipping-item:last-child {
  margin-bottom: 0px;
  border-bottom: none;
}

body.site.home #section-news .clipping-item .date {
  font-size: 12px;
  position: absolute;
}

body.site.home #section-news .clipping-item .date .month {
  font-size: 12px;
  color: #325d0d;
  font-weight: normal;
}

body.site.home #section-news .clipping-item a {
  display: block;
  padding-left: 46px;
}

/*-----------------------------------------*/
/*  SECTION-FEATURES
/*-----------------------------------------*/
body.site.home #section-features {
  padding: 30px 20px 0px 20px;
}

body.site.home #section-features #col-associados p {
  padding-bottom: 2px;
}

body.site.home #section-features #col-associados a {
  color: #333;
  font-size: 11px;
  text-decoration: underline;
}

body.site.home #section-features .image {
  margin: 14px 0px 18px 0px;
  width: 100%;
  height: 94px;
  border: 1px solid #ccc;
  background-repeat: no-repeat;
  background-size: cover;
}

body.site.home #section-features .image.associados {
  background-image: url("../images/site/home/feauture-associados.jpg");
}

body.site.home #section-features .image.receitas {
  background-image: url("../images/site/home/feauture-receitas.jpg");
}

body.site.home #section-features .image.estatisticas {
  background-image: url("../images/site/home/feauture-estatisticas.jpg");
}

body.site.home #section-features .image:hover {
  border: 1px solid #9e9e9e;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
}

body.site.home #section-features p {
  font-size: 13px;
  line-height: 21px;
}

/*-----------------------------------------*/
/*  SECTION-RECEITAS
/*-----------------------------------------*/
body.site.receitas #box-main-search #btnEnviarReceita {
  font-size: 12px;
  padding: 6px 9px 3px 0px;
  margin-right: 9px;
  border-right: 1px solid #c5c5c5;
  box-shadow: #fff 1px 0px 0px 0px;
}

body.site.receitas #box-main-search #btnEnviarReceita a {
  text-decoration: underline;
  color: #7a7a7a;
}

body.site.receitas #col-receitas-feature .feature .feature-image {
  margin-bottom: 7px;
}

body.site.receitas #col-receitas-feature .feature .feature-image img {
  width: 100%;
  max-width: 307px;
}

body.site.receitas #col-receitas-feature .feature .feature-title .feature-title-label {
  font-weight: bold;
}

body.site.receitas #col-receitas-feature .feature .feature-title .feature-title-author {
  font-size: 12px;
  color: #333333;
}

body.site.receitas #col-receitas-last {
  margin: 12px 0px 12px 0px;
}

body.site.receitas #sectionAllReceitas {
  margin-top: 22px;
}

body.site.receitas #sectionAllReceitas .item {
  border-top: 1px dotted #ccc;
  transition: all 100ms ease;
}

body.site.receitas #sectionAllReceitas .item:active {
  color: #000;
}

body.site.receitas #sectionAllReceitas .item:nth-child(odd) {
  border-right: 1px dotted #ccc;
}

body.site.receitas #sectionAllReceitas .item:hover {
  background-color: #f6f6f6;
}

body.site.receitas #sectionAllReceitas .item .item-body {
  padding: 15px 0px 15px 0px;
}

body.site.receitas #sectionAllReceitas .item .item-image {
  position: absolute;
  display: inline-block;
  border: 1px solid #ccc;
}

body.site.receitas #sectionAllReceitas .item .item-image img {
  width: 67px;
}

body.site.receitas #sectionAllReceitas .item .item-image + div {
  margin-left: 80px;
  min-height: 45px;
}

body.site.receitas #sectionAllReceitas .item .item-text {
  display: inline-block;
}

body.site.receitas #sectionAllReceitas .item .item-text .item-text-title {
  font-weight: bold;
}

body.site.receitas #sectionAllReceitas .item .item-text .item-text-excerpt {
  color: #555555;
  font-size: 12px;
}

body.site.receitas #section-all-posts .item {
  min-height: 94px;
}

@media (max-width: 768px) {
  .item:nth-child(odd) {
    border-right: none !important;
  }
}

@media (max-width: 540px) {
  body.site.receitas #col-receitas-feature, body.site.receitas #col-receitas-last {
    width: 100% !important;
  }
  body.site.receitas #col-receitas-feature {
    margin-bottom: 18px;
  }
}

/*-----------------------------------------*/
/*  SECTION-RECEITA
/*-----------------------------------------*/
body.site.receita #box-main-body.receita #receita-image {
  float: right;
  padding: 5px;
  background-color: #ededed;
  border: 1px solid #ccc;
}

body.site.receita #box-main-body.receita .p-title {
  font-weight: bold;
  padding: 0px;
}

@media (max-width: 540px) {
  body.site.receita #receita-image {
    float: inherit !important;
    margin-bottom: 14px;
  }
  body.site.receita #receita-image img {
    width: 100%;
  }
}

/*-----------------------------------------*/
/*  SECTION-NOTICIAS
/*-----------------------------------------*/
body.site.noticias #col-news-feature .feature .feature-image, body.site.search #col-news-feature .feature .feature-image {
  margin-bottom: 7px;
}

body.site.noticias #col-news-feature .feature .feature-image img, body.site.search #col-news-feature .feature .feature-image img {
  width: 100%;
  max-width: 307px;
}

body.site.noticias #col-news-feature .feature .feature-title .feature-title-label, body.site.search #col-news-feature .feature .feature-title .feature-title-label {
  font-weight: bold;
}

body.site.noticias #col-news-feature .feature .feature-title .feature-title-excerpt, body.site.search #col-news-feature .feature .feature-title .feature-title-excerpt {
  font-size: 12px;
  color: #333333;
}

body.site.noticias #clipping-col-1, body.site.search #clipping-col-1 {
  min-height: 220px;
  margin: 12px 0px 12px 0px;
}

body.site.noticias .clipping-item, body.site.search .clipping-item {
  font-weight: normal;
  font-size: 13px;
  border-bottom: 1px dotted #ccc;
  margin: 8px 0px;
  padding-bottom: 8px;
}

body.site.noticias .clipping-item:first-child, body.site.search .clipping-item:first-child {
  margin-top: 0px;
}

body.site.noticias .clipping-item:last-child, body.site.search .clipping-item:last-child {
  margin-bottom: 0px;
  border-bottom: none;
}

body.site.noticias .clipping-item .date, body.site.search .clipping-item .date {
  font-size: 12px;
  position: absolute;
}

body.site.noticias .clipping-item .date .month, body.site.search .clipping-item .date .month {
  font-size: 12px;
  color: #325d0d;
  font-weight: normal;
}

body.site.noticias .clipping-item a, body.site.search .clipping-item a {
  display: block;
  padding-left: 46px;
}

body.site.noticias #section-all-posts, body.site.search #section-all-posts {
  margin-top: 22px;
}

body.site.noticias #section-all-posts .item, body.site.search #section-all-posts .item {
  display: block;
  width: 100%;
  position: relative;
  cursor: pointer;
  border-top: 1px dotted #ccc;
  transition: all 100ms ease;
}

body.site.noticias #section-all-posts .item:active, body.site.search #section-all-posts .item:active {
  color: #000;
}

body.site.noticias #section-all-posts .item:hover, body.site.search #section-all-posts .item:hover {
  background-color: #f6f6f6;
}

body.site.noticias #section-all-posts .item .item-body, body.site.search #section-all-posts .item .item-body {
  padding: 15px 15px 15px 15px;
}

body.site.noticias #section-all-posts .item .item-image, body.site.search #section-all-posts .item .item-image {
  position: absolute;
  display: inline-block;
  border: 1px solid #ccc;
  min-height: 45px;
}

body.site.noticias #section-all-posts .item .item-image img, body.site.search #section-all-posts .item .item-image img {
  max-width: 70px;
}

body.site.noticias #section-all-posts .item .item-image + div, body.site.search #section-all-posts .item .item-image + div {
  margin-left: 80px;
  min-height: 45px;
}

body.site.noticias #section-all-posts .item .item-text, body.site.search #section-all-posts .item .item-text {
  display: inline-block;
}

body.site.noticias #section-all-posts .item .item-text .item-text-title, body.site.search #section-all-posts .item .item-text .item-text-title {
  font-weight: bold;
}

body.site.noticias #section-all-posts .item .item-text .item-text-excerpt, body.site.search #section-all-posts .item .item-text .item-text-excerpt {
  color: #555555;
}

@media (max-width: 540px) {
  body.site.noticias #col-news-feature, body.site.noticias #col-news-web {
    width: 100% !important;
  }
  body.site.noticias #col-news-feature {
    margin-bottom: 18px;
  }
}

/*-----------------------------------------*/
/*  SECTION-NOTICIA
/*-----------------------------------------*/
body.site.noticia #box-main-body.noticia img {
  width: 50%;
  max-width: 281px;
  float: left;
  border: 1px solid #ccc;
  padding: 2px;
  margin: 0px 10px 0px 0px;
}

body.site.noticia #box-main-body.noticia h2 {
  color: #333;
  padding-bottom: 4px;
}

body.site.noticia #box-main-body.noticia time {
  font-size: 12px;
  display: block;
  color: #7f7f7f;
  margin-bottom: 16px;
}

body.site.noticia #box-main-body.noticia p.author {
  color: #737373;
  font-size: 13px;
}

body.site.noticia #archives .archive-item {
  float: left;
  width: 98px;
  height: 98px;
  margin: 0px 8px 8px 0px;
}

body.site.noticia #archives .archive-item img {
  width: 100%;
}

.clipping-container > .row {
  display: flex;
  flex-wrap: wrap;
}

.clipping-container > .row > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.clipping-posts .item {
  display: block;
  position: relative;
}

.clipping-posts .item a {
  cursor: pointer;
}

.clipping-posts .item:hover .item-body {
  background: #f5f5f5;
}

.clipping-posts .item .item-body {
  padding: 15px 15px 15px 15px;
  margin-bottom: 5px;
  background: white;
  transition: background 0.4s;
}

.clipping-posts .item .item-body .item-text {
  display: inline-block;
}

.clipping-posts .item .item-body .item-text .item-text-title {
  font-weight: bold;
  margin-bottom: 3px;
}

.clipping-posts .item .item-body .item-text .item-text-excerpt {
  font-weight: normal;
  color: #555555;
}

.clipping-bar .title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
}

.clipping-bar .form .small {
  color: #b1b1b1;
}

.clipping-bar .form .group {
  float: left;
  margin-right: 16px;
}

.clipping-bar .form .group select {
  margin-top: 2px;
}

.clipping-bar .form .group input {
  margin-bottom: 4px;
}

.clipping-bar .form .group.button-group {
  padding-top: 20px;
}

/*-----------------------------------------*/
/*  SECTION-CONTATO
/*-----------------------------------------*/
body.contato #sectionForm {
  font-size: 12px;
}

body.contato #sectionForm .control-label {
  text-align: right;
  padding-right: 0px;
}

body.contato #sectionForm .form-group {
  margin-bottom: 5px;
}

body.contato #sectionForm .form-group #col-city {
  padding-right: 0px;
}

body.contato #sectionForm .form-group #col-state {
  padding-left: 5px;
}

body.contato #sectionForm input {
  padding: 4px;
}

@media (max-width: 992px) {
  body.contato #sectionForm .control-label {
    text-align: left;
    padding-right: 0px;
  }
  body.contato #sectionForm .form-group {
    margin-bottom: 5px;
  }
  body.contato #sectionForm .form-group #col-city {
    margin-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
  }
  body.contato #sectionForm .form-group #col-state {
    padding-left: 15px;
  }
}

/*-----------------------------------------*/
/*  PRINT
/*-----------------------------------------*/
@media print {
  .not-print, #box-header, #section-main-header, .no-print {
    display: none !important;
    visibility: hidden;
    height: 1px !important;
  }
  .not-print *, #box-header *, #section-main-header *, .no-print * {
    display: none;
  }
  body.site.receita #receita-image {
    float: inherit !important;
    width: 200px;
    margin-bottom: 14px;
  }
}

.container {
  max-width: 834px;
}

/*# sourceMappingURL=site.css.map */
